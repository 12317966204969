import {
  CLAIM_VIEWER,
  SALES_REP,
  CLAIM_ADMIN,
  CSR,
  CSR_MANAGER,
  ACTUARY,
  STARR_UNDERWRITER,
  CANTALK,
  SUPER_ADMIN,
} from '../../enums/userRoles';

class User {
  constructor(attributes = null) {
    this.attributes = attributes;

    return new Proxy(this, {
      get(user, prop) {
        if (prop in user) {
          return user[prop];
        }

        return user.attributes && prop in user.attributes ? user.attributes[prop] : undefined;
      },
    });
  }

  setAttributes(attributes) {
    this.attributes = attributes;
  }

  isValid() {
    return (
      typeof this.attributes === 'object' &&
      this.attributes !== null &&
      this.attributes.id &&
      !!this.attributes.api_token &&
      !!this.attributes.userlevel &&
      Array.isArray(this.attributes.regions)
    );
  }

  hasRegions() {
    return this.isValid() && this.regions.length > 0;
  }

  hasRole(role) {
    return this.isValid() && this.userlevel === role;
  }

  hasRoleIn(roles) {
    return this.isValid() && roles.includes(this.userlevel);
  }

  isActuary() {
    return this.hasRole(ACTUARY);
  }

  isCantalk() {
    return this.hasRole(CANTALK);
  }

  isClaimAdmin() {
    return this.hasRole(CLAIM_ADMIN);
  }

  isClaimViewer() {
    return this.hasRole(CLAIM_VIEWER);
  }

  isCsr() {
    return this.hasRole(CSR);
  }

  isCsrOrSuperAdmin() {
    return this.hasRoleIn([CSR, SUPER_ADMIN]);
  }

  isSalesRepCsrOrSuperAdmin() {
    return this.hasRoleIn([SALES_REP, CSR, SUPER_ADMIN]);
  }

  isInternal() {
    return this.hasRoleIn([SALES_REP, CLAIM_ADMIN, CSR, CSR_MANAGER, SUPER_ADMIN]);
  }

  isSalesRep() {
    return this.hasRole(SALES_REP);
  }

  isStarrUnderwriter() {
    return this.hasRole(STARR_UNDERWRITER);
  }

  isRegionalManager() {
    return parseInt(this.regiongroupid, 10) > 0;
  }

  isSuperAdmin() {
    return this.hasRole(SUPER_ADMIN);
  }

  isVP() {
    return parseInt(this.id, 10) === 23;
  }

  canEditAgreement() {
    return this.hasRoleIn([CLAIM_ADMIN, CSR, SUPER_ADMIN]);
  }

  canEditClaim() {
    return this.hasRoleIn([CLAIM_ADMIN, SUPER_ADMIN]);
  }

  getDefaultRegionId(defaultValue = null) {
    return this.hasRegions() ? this.regions[0].id : defaultValue;
  }
}

export default User;
